import React, { useContext } from "react"
import { Paper } from "@mui/material"
import AccountMenu from "../components/AccountMenu"

import NavButton from "../components/NavButton"
import UserService from "src/UserService"
import { UserContext } from "src/providers/UserContext"
import { AssessmentRounded, DashboardRounded, FolderRounded, HistoryEduRounded, HomeRounded, InsertDriveFileRounded } from "@mui/icons-material"
import { UserRole } from "src/models/common"

const PageHeader: React.FC = () => {
  const { user } = useContext(UserContext)

  return (
    <Paper elevation={4} className="page-header">
      <div className="flex-spacer">
        <img className="logo" src="iqvia_logo.svg" alt="Application logo" />
      </div>

      {!!user && (<>
        <div className="flex-jc-center naviagtion">
          <NavButton path="/" label="Home" icon={<HomeRounded />}></NavButton>
          {UserService.hasRoles([UserRole.BUYER]) &&
            <NavButton path="/document" label="Translate File" icon={<InsertDriveFileRounded />}></NavButton>}
          <NavButton path="/text" label="Translate Text" icon={<HistoryEduRounded />}></NavButton>
          {UserService.hasRoles([UserRole.EXFLUENCY_ADMIN, UserRole.PLATFORM_MANAGER]) && (<>
            <NavButton path="/bi" label="BI Overview" icon={<AssessmentRounded />}></NavButton>
            <NavButton path={process.env.REACT_APP_DASHBOARDS_URL || ''} label="Admin Dashboard" icon={<DashboardRounded />} external></NavButton>
          </>)}
          <NavButton path="/files" label="My Files" icon={<FolderRounded />}></NavButton>
        </div>
        <div className="flex-jc-end avatar">
          <AccountMenu username={user.login} />
        </div>
      </>)}
    </Paper>
  )
}
export default PageHeader